<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/solid';
import type { PropType } from 'vue';
import { isGobyInstalled } from '~/utilities/walletAdapter/gobyAdapter';

const props = defineProps({
  modelValue: Boolean as PropType<boolean>,
});
const emit = defineEmits(['update:modelValue']);

const gobyStore = useGobyStore();

const navTo = (path: string) => {
  navigateTo(path);
  emit('update:modelValue', false);
};
</script>
<template>
  <TransitionRoot as="template" :show="props.modelValue">
    <Dialog as="div" class="relative z-50">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral-500/50 transition-opacity dark:bg-neutral-400/50" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all dark:bg-black sm:my-8 sm:w-full sm:max-w-md sm:p-10"
            >
              <div class="absolute right-0 top-0 hidden pr-6 pt-6 sm:block">
                <button
                  type="button"
                  class="rounded-md bg-white text-neutral-400 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-black dark:text-neutral-400 dark:text-neutral-500"
                  @click="emit('update:modelValue', false)"
                >
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div>
                <div class="text-center">
                  <DialogTitle as="h3" class="text-xl font-semibold leading-6 text-neutral-900 dark:text-neutral-50"
                    >Connect your Wallet</DialogTitle
                  >
                  <div class="mt-8 flex flex-col gap-4">
                    <button
                      v-if="isGobyInstalled()"
                      class="shadow-attribute hover:shadow-card-hover duration-50 flex w-full justify-between rounded-lg px-6 py-4 font-bold text-neutral-700 transition ease-in-out dark:text-neutral-200"
                      @click="gobyStore.connect('goby')"
                    >
                      <span class="flex gap-4">
                        <img :class="[' h-6 w-6']" aria-hidden="true" src="/goby-logo.svg" />Goby
                      </span>
                      <span
                        :class="[
                          gobyStore.type === 'goby' && gobyStore.account
                            ? 'text-green-600'
                            : 'text-neutral-600 dark:text-neutral-300',
                        ]"
                        >{{ gobyStore.type === 'goby' && gobyStore.account ? 'Connected' : 'Connect' }}</span
                      >
                    </button>
                    <NuxtLink
                      v-else
                      to="https://www.goby.app"
                      target="_blank"
                      class="shadow-attribute hover:shadow-card-hover duration-50 flex w-full justify-between rounded-lg px-6 py-4 font-bold text-neutral-700 transition ease-in-out dark:text-neutral-200"
                    >
                      <span class="flex gap-4">
                        <img :class="[' h-6 w-6']" aria-hidden="true" src="/goby-logo.svg" />Goby
                      </span>
                      <span :class="[gobyStore.account ? 'text-green-600' : 'text-neutral-600 dark:text-neutral-300']"
                        >Install</span
                      >
                    </NuxtLink>
                    <button
                      class="shadow-attribute hover:shadow-card-hover duration-50 flex w-full justify-between rounded-lg px-6 py-4 font-bold text-neutral-700 transition ease-in-out dark:text-neutral-200"
                      @click="gobyStore.connect('walletconnect')"
                    >
                      <span class="flex gap-4">
                        <img
                          :class="[' h-6 w-6']"
                          aria-hidden="true"
                          src="https://sagewallet.net/assets/sage-logo.png"
                        />Sage
                      </span>
                      <span
                        :class="[
                          gobyStore.type === 'walletconnect' && gobyStore.account
                            ? 'text-green-600'
                            : 'text-neutral-600 dark:text-neutral-300',
                        ]"
                        >{{ gobyStore.type === 'walletconnect' && gobyStore.account ? 'Connected' : 'Connect' }}</span
                      >
                    </button>
                  </div>
                  <div class="mt-6">
                    <NuxtLink
                      to="/login"
                      class="text-sm text-neutral-600 hover:text-neutral-700 dark:text-neutral-200 dark:hover:text-neutral-300"
                      @click="navTo('/login')"
                      >Legacy Login</NuxtLink
                    >
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
