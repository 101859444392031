import { WalletConnectModal } from '@walletconnect/modal';
import SignClient from '@walletconnect/sign-client';
import { getSdkError } from '@walletconnect/utils';
import type {
  AssetBalanceResp,
  CoinSpend,
  getAssetCoinsParams,
  MintNFTParams,
  SignMessageParams,
  SpendableCoin,
  SpendBundle,
  TransactionResp,
  WalletAdapter,
} from '.';

const projectId = 'c4a526d68137397a45fef408ba2e4e5e';

class WalletConnectAdapter implements WalletAdapter {
  signClient?: SignClient;

  network: 'testnet' | 'mainnet' = 'testnet';

  on(event: string, callback: (...args: any[]) => void) {
    // noop for now
  }

  async getSignClient() {
    if (!this.signClient) {
      this.signClient = await SignClient.init({
        projectId,
        relayUrl: 'wss://relay.walletconnect.org',
        metadata: {
          name: 'MintGarden',
          description: 'Explore and mint NFTs in the Chia ecosystem.',
          url: 'https://mintgarden.io',
          icons: ['https://assets.mainnet.mintgarden.io/web/mint-logo-round.svg'],
        },
      });
    }
    return this.signClient;
  }

  async walletSwitchChain(params: { chainId: string }) {
    if (['testnet', 'mainnet'].includes(params.chainId)) {
      this.network = params.chainId as 'testnet' | 'mainnet';
    } else {
      throw new Error('Unsupported network');
    }
    return null;
  }

  async chainId() {
    return this.network;
  }

  async accounts() {
    const sessions = await (await this.getSignClient()).session.getAll();
    if (sessions.length > 0) {
      const lastSession = sessions[sessions.length - 1];

      const allNamespaceAccounts = Object.values(lastSession.namespaces)
        .map((namespace) => namespace.accounts)
        .flat()
        .map((account) => account.split(':')[2]);

      return allNamespaceAccounts;
    }
    return [];
  }

  async getPublicKeys(params: { limit?: number; offset?: number } = { limit: 10, offset: 0 }) {
    return this.sendWalletConnectMessage<string[]>('chip0002_getPublicKeys', params);
  }

  async getAssetBalance(params: { type: string | null; assetId: string | null; includedLocked?: boolean }) {
    return this.sendWalletConnectMessage<AssetBalanceResp>('chip0002_getAssetBalance', params);
  }

  async getAssetCoins(params: getAssetCoinsParams) {
    return this.sendWalletConnectMessage<SpendableCoin[]>('chip0002_getAssetCoins', { assetId: null, ...params });
  }

  async filterUnlockedCoins(params: { coinNames: string[] }) {
    return this.sendWalletConnectMessage<string[]>('chip0002_filterUnlockedCoins', params);
  }

  async signMessage({ message, publicKey }: SignMessageParams) {
    return this.sendWalletConnectMessage<string>('chip0002_signMessage', { message, publicKey });
  }

  async signCoinSpends(params: { coinSpends: CoinSpend[] }) {
    return this.sendWalletConnectMessage<string>('chip0002_signCoinSpends', { coinSpends: params.coinSpends });
  }

  async sendTransaction(params: { spendBundle: SpendBundle }) {
    return this.sendWalletConnectMessage<TransactionResp[]>('chip0002_sendTransaction', {
      spendBundle: params.spendBundle,
    });
  }

  async estimateCoinSpendsFee(params: { coinSpends: CoinSpend[] }) {
    return { estimates: [0, 0, 0] as [number, number, number] };
  }

  createOffer(params: CreateOfferParams) {
    return this.sendWalletConnectMessage<{ offer: string }>('chia_createOffer', params);
  }

  takeOffer(params: { offer: string }) {
    return this.sendWalletConnectMessage<boolean>('chia_takeOffer', params);
  }

  mintNFT(_: MintNFTParams): Promise<boolean> {
    throw new Error('Not implemented');
  }

  async connect(params: { eager?: boolean }) {
    const signClient = await this.getSignClient();
    const sessions = signClient.session.getAll();
    if (sessions.length > 0) {
      return true;
    }

    if (params.eager) {
      return false;
    }

    try {
      const { uri, approval } = await signClient.connect({
        requiredNamespaces: {
          chia: {
            methods: [
              'chip0002_getPublicKeys',
              'chip0002_filterUnlockedCoins',
              'chip0002_getAssetCoins',
              'chip0002_getAssetBalance',
              'chip0002_signCoinSpends',
              'chip0002_signMessage',
              'chip0002_sendTransaction',
              'chia_createOffer',
              'chia_takeOffer',
            ],
            chains: [`chia:${this.network}`],
            events: ['chip0002_chainChanged', 'chip0002_accountsChanged'],
          },
        },
      });

      if (uri) {
        const walletConnectModal = new WalletConnectModal({
          projectId,
          explorerRecommendedWalletIds: 'NONE',
        });
        walletConnectModal.openModal({ uri });

        await approval();

        walletConnectModal.closeModal();

        return true;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  async disconnect() {
    const signClient = await this.getSignClient();
    const sessions = signClient.session.values;
    for (const session of sessions) {
      await signClient.disconnect({
        topic: session.topic,
        reason: getSdkError('USER_DISCONNECTED'),
      });
    }
    return true;
  }

  private async sendWalletConnectMessage<T>(method: string, params: any): Promise<T> {
    const signClient = await this.getSignClient();
    const sessions = signClient.session.getAll();
    if (sessions.length > 0) {
      const request = {
        method,
        params,
      };
      console.log('request', request);
      try {
        const result = await signClient.request({
          topic: sessions[0].topic,
          chainId: `chia:${this.network}`,
          request,
        });
        console.log('result', result);
        if (result === null) {
          throw new Error('Request rejected in wallet');
        }
        return result as T;
      } catch (error) {
        console.error(`Error sending ${method} message:`, error);
        throw error;
      }
    } else {
      throw new Error('No wallet connected');
    }
  }
}

export const walletConnectAdapter = new WalletConnectAdapter();
