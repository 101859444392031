import { bech32m } from 'bech32';
import { useStorage } from '@vueuse/core';
/* eslint-disable import/default */
import chiaBls from 'chia-bls';
import clvmLib from 'clvm-lib';
import chiaRpc from 'chia-rpc';
/* eslint-enable import/default */
import type { CoinSpend, SpendableCoin, SpendBundle } from '~/types/wallet';
import { STANDARD_PUZZLE_MOD } from '~/utilities/puzzles';
import { gobyAdapter, isGobyInstalled } from '~/utilities/walletAdapter/gobyAdapter';
import { walletConnectAdapter } from '~/utilities/walletAdapter/walletConnectAdapter';
import type { ProfileWithCoin } from '~/types/store';
import { getApiRoot } from '~/utilities/api';
import type { Profile } from '~/types/generated/models/Profile';

/* eslint-disable import/no-named-as-default-member */
export const { fromHex, toHex, hash256, concatBytes, JacobianPoint } = chiaBls;
export const { Program } = clvmLib;
export const { toCoinId } = chiaRpc;
/* eslint-enable import/no-named-as-default-member */

declare global {
  interface Window {
    chia: any;
    goby: any;
  }
}

const timeout = (ms: number) =>
  new Promise<void>((_resolve, reject) => setTimeout(() => reject(new Error('Time out!')), ms));

export type DidCoin = SpendableCoin & { id: string };

const walletType = useStorage('wallet-type', '');

export const useGobyStore = defineStore('walletStore', {
  state: () => ({
    isInitialized: false,
    account: undefined as string | undefined,
    publicKey: undefined as string | undefined,
    isWalletInstalled: undefined as boolean | undefined,
    profiles: undefined as ProfileWithCoin[] | undefined,
    nftCoins: undefined as SpendableCoin[] | undefined,
  }),
  getters: {
    type: () => walletType.value,
    wallet: () => {
      if (walletType.value === 'goby') {
        return gobyAdapter;
      } else if (walletType.value === 'walletconnect') {
        return walletConnectAdapter;
      }
      throw new Error('Unsupported wallet type');
    },
    counts: (state) => ({
      did: state.profiles?.length,
      nft: state.nftCoins?.length,
    }),
    profile: (state) => (profileId: string) => state.profiles?.find((p) => p.id === profileId),

    p2Puzzle: (state) => {
      if (!state.publicKey) return;
      console.log('state.publicKey', state.publicKey);

      const key = JacobianPoint.fromHex(state.publicKey, false);
      return STANDARD_PUZZLE_MOD.curry([Program.fromJacobianPoint(key)]);
    },
  },
  actions: {
    async connect(type: 'goby' | 'walletconnect', eager = false) {
      walletType.value = type;
      if (!eager) {
        if ((await this.wallet.chainId()) !== 'mainnet') {
          await this.wallet.walletSwitchChain({ chainId: 'mainnet' });
        }
      }
      await this.wallet.connect({ eager });
      await this.initialize();
    },
    getIsWalletInstalled() {
      if (walletType.value === 'goby') {
        return isGobyInstalled();
      }
      return true;
    },
    disconnect() {
      if (walletType.value === 'walletconnect') {
        this.wallet.disconnect();
      }
      walletType.value = '';
      this.account = '';
      this.publicKey = '';
      this.isWalletInstalled = undefined;
      this.isInitialized = false;
    },
    async initialize() {
      if (import.meta.client) {
        console.log('this.isWalletInstalled', this.isWalletInstalled);
        console.log('this.account', this.account);
        if (this.isWalletInstalled && this.account) {
          // Nothing to do
        } else if (this.getIsWalletInstalled()) {
          this.isWalletInstalled = true;

          const initAccount = async () => {
            this.publicKey = (await this.wallet.getPublicKeys())[0]?.replace('0x', '');
            await this.setAccount();
          };

          try {
            await Promise.race([this.wallet.connect({ eager: true }), timeout(10000)]);
            if ((await this.wallet.chainId()) !== 'mainnet') {
              await this.wallet.walletSwitchChain({ chainId: 'mainnet' });
            }
            await initAccount();

            this.wallet.on('accountChanged', () => initAccount());
            this.wallet.on('chainChanged', () => window.location.reload());
          } catch (e) {
            this.account = '';
          }
        } else {
          this.isWalletInstalled = false;
          this.account = '';
        }
        console.log('Initialized wallet');
        this.isInitialized = true;
      }
    },
    async initializeAssets() {
      if (process.client && this.isWalletInstalled) {
        await Promise.all([this.loadNfts(), this.loadProfiles()]);
      }
    },
    async setAccount() {
      const accounts = await this.wallet.accounts();
      console.log('accounts', accounts);

      const account = accounts?.[0];
      if (walletType.value === 'walletconnect') {
        this.account = account || '';
      } else {
        this.account = account ? bech32m.encode('xch', bech32m.toWords(fromHex(account))) : '';
      }
    },
    async loadNfts() {
      if (walletType.value === 'goby') {
        this.nftCoins = await this.wallet.getAssetCoins({ type: 'nft', limit: 10 });
      } else {
        this.nftCoins = [];
      }
    },
    async loadProfiles() {
      const loadedProfiles = [];
      const didCoins = await this.wallet.getAssetCoins({ type: 'did', includedLocked: true, limit: 10 });
      for (const didCoin of didCoins) {
        const didPuzzle = Program.deserializeHex(didCoin.puzzle);
        const [, curriedArgs] = didPuzzle.uncurry()!;
        const launcherId = curriedArgs[0].rest.first;
        const didInnerPuzzle = curriedArgs[1];
        const [, innerCurriedArgs] = didInnerPuzzle.uncurry()!;
        const p2Puzzle = innerCurriedArgs[0];
        const [, p2CurriedArgs] = p2Puzzle.uncurry()!;
        const publicKey = p2CurriedArgs[0].toHex();

        let profile;
        try {
          profile = await $fetch<ProfileWithCoin>(`${getApiRoot()}/profile/${launcherId.toHex()}`);
          profile.didCoin = didCoin;
          profile.publicKey = publicKey;
        } catch (e) {
          profile = {
            didCoin,
            id: launcherId.toHex(),
            encoded_id: bech32m.encode('did:chia:', bech32m.toWords(launcherId.toBytes())),
            verification_state: 0,
            publicKey,
          };
        }
        console.log('profile', profile.encoded_id);
        loadedProfiles.push(profile);
      }
      this.profiles = loadedProfiles;
    },
    async refreshProfile(profile: Profile) {
      try {
        const loadedProfile = await $fetch<Profile>(`${getApiRoot()}/profile/${profile.id}`);
        if (this.profiles && loadedProfile) {
          const index = this.profiles?.findIndex((p) => p.id === profile.id);
          if (index !== undefined) {
            const didCoin = this.profiles[index].didCoin;
            this.profiles.splice(index, 1, { ...loadedProfile, didCoin });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },

    signCoinSpends(coinSpends: CoinSpend[]) {
      return this.wallet.signCoinSpends({ coinSpends });
    },
    estimateCoinSpendsFee(coinSpends: CoinSpend[]) {
      return Promise.resolve({ estimates: [0, 0, 0] });
      // return goby.estimateCoinSpendsFee({ coinSpends });
    },
    sendTransaction(spendBundle: SpendBundle) {
      return this.wallet.sendTransaction({ spendBundle });
    },
    async signMessage(message: string, publicKey?: string) {
      const publicKeyToUse = publicKey || this.publicKey;
      if (!publicKeyToUse) {
        throw new Error('No public key');
      }
      const signature = await this.wallet.signMessage({ message, publicKey: publicKeyToUse });
      return { publicKey: publicKeyToUse, signature };
    },
    async getAssetCoins(
      type: 'did' | 'nft' | 'cat' | null,
      assetId: string | null = null,
      includedLocked?: boolean,
      totalAmount: number = 0
    ) {
      const coins: SpendableCoin[] = [];

      const limit = 20;
      let offset = 0;

      do {
        console.log(`Getting ${limit} coins, offset ${offset}`);
        const assetCoins = await this.wallet.getAssetCoins({ type, assetId, includedLocked, limit, offset });
        coins.push(...assetCoins);
        if (assetCoins.length < limit) {
          break;
        }
        offset += limit;
      } while (coins.reduce((acc, coin) => acc + coin.coin.amount, 0) < totalAmount);

      if (coins.reduce((acc, coin) => acc + coin.coin.amount, 0) < totalAmount) {
        throw new Error('Your balance is insufficient.');
      }

      return coins;
    },
    async getAssetBalance(type: 'did' | 'nft' | null, assetId?: string, includedLocked?: boolean) {
      const coins = await this.getAssetCoins(type, assetId, includedLocked);
      return coins.reduce((acc, coin) => acc + coin.coin.amount, 0);
    },
    createOffer(params: CreateOfferParams) {
      return this.wallet.createOffer(params);
    },
    takeOffer(offerBech32: string) {
      return this.wallet.takeOffer({ offer: offerBech32 });
    },
    mintNft(metadataInfo: MetadataInfo, didId: string, royaltyAddress?: string, royaltyPercentage?: number) {
      return this.wallet.mintNFT({ metadataInfo, didId, royaltyAddress, royaltyPercentage });
    },
  },
});
