<script setup>
import { useIntervalFn } from '@vueuse/core';
import { intervalToDuration } from 'date-fns';

const props = defineProps(['end', 'offers', 'inverted']);
const { end, inverted } = toRefs(props);

const now = ref(new Date());
setInterval(() => {
  now.value = new Date();
}, 1000);

const difference = ref(
  intervalToDuration({
    start: new Date(),
    end: end.value,
  })
);
useIntervalFn(() => {
  difference.value = intervalToDuration({
    start: new Date(),
    end: end.value,
  });
}, 1000);

function pad(num, size) {
  let s = num + '';
  while (s.length < size) s = '0' + s;
  return s;
}

const differenceFormatted = computed(() => {
  if (difference.value.days >= 1) {
    const d = pad(difference.value.days, 2);
    const h = pad(difference.value.hours, 2);
    const m = pad(difference.value.minutes, 2);
    return `${d}d ${h}h ${m}m`;
  } else {
    const h = pad(difference.value.hours, 2);
    const m = pad(difference.value.minutes, 2);
    const s = pad(difference.value.seconds, 2);
    return `${h}:${m}:${s}`;
  }
});
</script>
<template>
  <div>
    <div
      class="text font-bold tabular-nums"
      :class="inverted ? 'text-black dark:text-white' : 'text-white dark:text-black'"
    >
      {{ differenceFormatted }}
    </div>
  </div>
</template>
