<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { MenuIcon, SearchIcon, XIcon } from '@heroicons/vue/outline';
//
// const encodeAccount = (account) => {
//   if (!Buffer.isBuffer(account)) {
//     account = Buffer.from(account, 'hex');
//   }
//   const address = bech32m.encode('xch', bech32m.toWords(account));
//   return `${address.slice(0, 5)}...${address.slice(-4)}`;
// };

const leftNavigation = [
  { name: 'mints', to: '/mints' },
  {
    name: 'rankings',
    flyout: [
      { name: 'collections', to: '/collections' },
      { name: 'profiles', to: '/profiles' },
    ],
  },
];
const rightNavigation = [
  { name: 'mint', to: '/mint' },
  { name: 'activity', to: '/events' },
];
const mobileNavigation = [...leftNavigation, ...rightNavigation].flatMap((entry) =>
  entry.flyout ? entry.flyout : entry
);

const showMobileSearch = ref(false);
const route = useRoute();
</script>

<template>
  <Popover class="relative w-full">
    <div class="absolute z-50 w-full">
      <nav
        class="relative mx-auto flex items-center justify-between px-4 pt-4 sm:px-8 lg:px-16 lg:py-3"
        aria-label="Global"
      >
        <div class="grid w-full grid-cols-2 gap-8 md:grid-cols-3">
          <div class="flex h-full w-full items-center lg:w-auto">
            <NuxtLink :class="showMobileSearch ? 'hidden' : 'block'" class="flex-shrink-0 p-2 lg:block" to="/">
              <span class="sr-only">MintGarden</span>
              <img
                class="hidden h-8 w-[9.5rem] sm:h-10 sm:w-[12rem] md:block"
                :class="route.meta?.whiteNavbar ? 'image-white' : 'dark:image-white'"
                src="/mintgarden-logo.svg"
                alt=""
              />
              <img
                class="h-10 px-4 md:hidden"
                :class="route.meta?.whiteNavbar ? 'image-white' : ''"
                src="/mint-logo.svg"
                alt=""
              />
            </NuxtLink>
            <div v-if="showMobileSearch" class="mr-4 flex-1 lg:hidden">
              <NavbarSearch @close="showMobileSearch = false" />
            </div>

            <div class="hidden items-center pt-[0.5rem] lg:ml-10 lg:space-x-10 xl:flex">
              <template v-for="item in leftNavigation" :key="item.name">
                <Popover v-if="item.flyout" class="relative inline" v-slot="{ close }">
                  <PopoverButton
                    class="inline-flex items-center gap-x-1 leading-6"
                    :class="[
                      'whitespace-nowrap',
                      route.meta?.whiteNavbar
                        ? 'font-semibold text-neutral-100 hover:text-white'
                        : 'font-medium text-neutral-800 hover:text-black dark:text-neutral-100',
                    ]"
                  >
                    <span>{{ $t(`header.${item.name}`) }}</span>
                  </PopoverButton>

                  <transition
                    enter-active-class="transition ease-out duration-200"
                    enter-from-class="opacity-0 translate-y-1"
                    enter-to-class="opacity-100 translate-y-0"
                    leave-active-class="transition ease-in duration-150"
                    leave-from-class="opacity-100 translate-y-0"
                    leave-to-class="opacity-0 translate-y-1"
                  >
                    <PopoverPanel class="absolute left-1/2 z-10 mt-4 flex w-screen max-w-min -translate-x-1/2 px-4">
                      <div
                        class="w-56 shrink rounded-xl bg-white p-3 text-sm font-semibold leading-6 shadow-lg ring-1 ring-neutral-900/5 dark:bg-black"
                      >
                        <NuxtLink
                          v-for="flyoutItem in item.flyout"
                          :key="flyoutItem.name"
                          :href="flyoutItem.to"
                          @click="close"
                          class="block p-2 text-neutral-800 hover:text-black dark:text-neutral-100 dark:hover:text-white"
                          >{{ $t(`header.${flyoutItem.name}`) }}
                        </NuxtLink>
                      </div>
                    </PopoverPanel>
                  </transition>
                </Popover>
                <NuxtLink
                  v-else
                  :to="item.to"
                  active-class="text-black dark:text-white"
                  :class="[
                    'whitespace-nowrap',
                    ['api', 'mint'].includes(item.name) ? 'hidden lg:inline' : '',
                    route.meta?.whiteNavbar
                      ? 'font-semibold text-neutral-100 hover:text-white'
                      : 'font-medium text-neutral-800 hover:text-black dark:text-neutral-100',
                  ]"
                  >{{ $t(`header.${item.name}`) }}
                </NuxtLink>
              </template>
            </div>
          </div>
          <div class="mx-auto my-auto hidden w-full max-w-xl md:block lg:space-x-10">
            <NavbarSearch />
          </div>
          <div class="flex items-center justify-end">
            <div class="-mr-2 flex items-center gap-1 lg:hidden">
              <div class="md:hidden">
                <a
                  href="#"
                  :class="
                    route.meta?.whiteNavbar
                      ? 'text-neutral-100 focus:ring-white dark:text-neutral-800'
                      : 'text-neutral-400 focus:ring-black dark:text-neutral-500'
                  "
                  class="inline-flex items-center justify-center rounded-full p-2 backdrop-blur hover:bg-neutral-100 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black dark:text-neutral-400 dark:hover:bg-neutral-800"
                  @click.prevent="showMobileSearch = !showMobileSearch"
                >
                  <XIcon v-if="showMobileSearch" class="h-6 w-6" aria-hidden="true" />
                  <SearchIcon v-else class="h-6 w-6" aria-hidden="true" />
                </a>
              </div>
              <PopoverButton
                :class="
                  route.meta?.whiteNavbar
                    ? 'text-neutral-100 focus:ring-white dark:text-neutral-800'
                    : 'text-neutral-400 focus:ring-black dark:text-neutral-500'
                "
                class="inline-flex items-center justify-center rounded-full p-2 hover:bg-neutral-100 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-inset dark:text-neutral-400 dark:hover:bg-neutral-800"
              >
                <span class="sr-only">Open main menu</span>
                <MenuIcon class="h-45 w-6" aria-hidden="true" />
              </PopoverButton>
              <div class="inline-flex items-center justify-center rounded-lg p-2">
                <NavbarLogin />
              </div>
            </div>
            <div class="hidden lg:ml-10 lg:block lg:space-x-10">
              <template v-for="item in rightNavigation" :key="item.name">
                <NuxtLink
                  :to="item.to"
                  active-class="text-black dark:text-white"
                  :class="[
                    'whitespace-nowrap',
                    ['api', 'mint'].includes(item.name) ? 'hidden lg:inline' : '',
                    route.meta?.whiteNavbar
                      ? 'font-semibold text-neutral-100 hover:text-white'
                      : 'font-medium text-neutral-800 hover:text-black dark:text-neutral-100',
                  ]"
                  >{{ $t(`header.${item.name}`) }}
                </NuxtLink>
              </template>
            </div>
            <div class="hidden lg:ml-6 lg:block lg:space-x-10">
              <NavbarLogin />
            </div>
          </div>
        </div>
      </nav>

      <!-- Mobile menu -->
      <PopoverPanel
        v-slot="{ close }"
        focus
        class="absolute inset-x-0 top-0 z-[200] origin-top-right transform p-2 transition lg:hidden"
      >
        <div class="ring-opacity-5 overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black dark:bg-black">
          <div class="flex items-center justify-between px-5 pt-4">
            <NuxtLink to="/" @click="close()">
              <img class="h-8 w-auto" src="/mintgarden-logo.svg" alt="" />
            </NuxtLink>
            <div class="-mr-2">
              <PopoverButton
                class="inline-flex items-center justify-center rounded-lg bg-white p-2 text-neutral-400 hover:bg-neutral-100 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black dark:bg-black dark:text-neutral-400 dark:text-neutral-500 dark:hover:bg-neutral-800"
              >
                <span class="sr-only">Close main menu</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </PopoverButton>
            </div>
          </div>
          <div class="space-y-1 px-2 pb-3 pt-2">
            <NuxtLink
              v-for="item in mobileNavigation"
              :key="item.name"
              :to="item.to"
              class="block rounded-lg px-3 py-2 text-base font-medium text-neutral-700 hover:bg-neutral-50 hover:text-neutral-900 dark:text-neutral-200 dark:text-neutral-50 dark:hover:bg-neutral-900"
              @click="close()"
              >{{ $t(`header.${item.name}`) }}
            </NuxtLink>
            <NavbarLogin />
          </div>
        </div>
      </PopoverPanel>
    </div>
  </Popover>
</template>
